var render = function () {
  var _vm$redeem, _vm$redeem2, _vm$redeem3, _vm$redeem4, _vm$redeem5, _vm$redeem6, _vm$redeem7, _vm$redeem8, _vm$redeem9, _vm$redeem10, _vm$redeem11, _vm$redeem12, _vm$redeem13, _vm$redeem14, _vm$redeem15, _vm$redeem16, _vm$redeem17, _vm$redeem18, _vm$redeem19, _vm$redeem20, _vm$redeem21, _vm$redeem22, _vm$redeem23, _vm$redeem24, _vm$redeem25, _vm$redeem26, _vm$redeem27, _vm$redeem28, _vm$redeem29, _vm$refund, _vm$refund2, _vm$refund3, _vm$refund4, _vm$redeem30, _vm$redeem31, _vm$refund5, _vm$redeem32, _vm$refund6, _vm$redeem33, _vm$redeem34, _vm$redeem35;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "max-width": "1270px",
      "width": "100%",
      "background-color": ['#F2F2F2', '#FFF'],
      "margin-inline": "auto",
      "min-height": ['calc(100vh - 62px)', 'unset']
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "0px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Profil',
        href: '/profile'
      }, {
        label: 'Kode AjakSehat',
        href: '/profile/referral'
      }, {
        label: 'Riwayat Poin',
        href: '/profile/referral/redeem?tab=history'
      }, {
        label: _vm.isRefund ? 'Pengembalian Poin' : 'Penukaran Hadiah',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['20px', '30px']
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [(_vm$redeem = _vm.redeem) !== null && _vm$redeem !== void 0 && _vm$redeem.status ? _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": ['600', '600'],
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.isRefund ? 'Pengembalian Poin' : ((_vm$redeem2 = _vm.redeem) === null || _vm$redeem2 === void 0 ? void 0 : _vm$redeem2.status) == 'inprogress' ? 'Penukaran Sedang Diproses' : ((_vm$redeem3 = _vm.redeem) === null || _vm$redeem3 === void 0 ? void 0 : _vm$redeem3.status) == 'success' ? 'Penukaran Hadiah Berhasil' : 'Penukaran Hadiah Gagal') + " ")]), _c('c-image', {
    attrs: {
      "src": _vm.isRefund ? require('@/assets/images/image-refund.svg') : ((_vm$redeem4 = _vm.redeem) === null || _vm$redeem4 === void 0 ? void 0 : _vm$redeem4.status) == 'inprogress' ? require('@/assets/images/image-in-progress.png') : ((_vm$redeem5 = _vm.redeem) === null || _vm$redeem5 === void 0 ? void 0 : _vm$redeem5.status) == 'success' ? require('@/assets/images/image-payment-success.svg') : ((_vm$redeem6 = _vm.redeem) === null || _vm$redeem6 === void 0 ? void 0 : _vm$redeem6.status) == 'failed' ? require('@/assets/images/illustration-6-raw.png') : require('@/assets/images/image-blank.png'),
      "width": "100%",
      "height": "auto",
      "max-width": ['200px', '300px'],
      "max-height": ['170px', '250px'],
      "margin-top": "20px",
      "margin-inline": "auto"
    }
  }), ((_vm$redeem7 = _vm.redeem) === null || _vm$redeem7 === void 0 ? void 0 : _vm$redeem7.status) == 'inprogress' ? _c('c-text', {
    attrs: {
      "margin-top": ['8px', '16px'],
      "font-size": ['14px', '18px'],
      "font-weight": ['400', '500'],
      "text-align": "center",
      "padding-inline": "40px"
    }
  }, [_vm._v(" Penukaran hadiah akan diproses"), _c('br'), _vm._v(" dalam jangka waktu 1x24 jam ")]) : _vm._e(), ((_vm$redeem8 = _vm.redeem) === null || _vm$redeem8 === void 0 ? void 0 : _vm$redeem8.status) == 'failed' && _vm.isRefund ? _c('c-text', {
    attrs: {
      "margin-top": ['8px', '16px'],
      "font-size": ['14px', '18px'],
      "font-weight": ['400', '500'],
      "text-align": "center",
      "padding-inline": "40px"
    }
  }, [_vm._v(" Poin kamu dikembalikan "), _c('br'), _vm._v(" karena penukaran hadiah gagal ")]) : ((_vm$redeem9 = _vm.redeem) === null || _vm$redeem9 === void 0 ? void 0 : _vm$redeem9.status) == 'failed' && !_vm.isRefund ? _c('c-text', {
    attrs: {
      "margin-top": ['8px', '16px'],
      "font-size": ['14px', '18px'],
      "font-weight": ['400', '500'],
      "text-align": "center",
      "padding-inline": "40px"
    }
  }, [_vm._v(" Penukaran hadiahmu gagal dikarenakan " + _vm._s((_vm$redeem10 = _vm.redeem) === null || _vm$redeem10 === void 0 ? void 0 : _vm$redeem10.note) + " ")]) : _vm._e()], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "900px",
      "margin-inline": "auto",
      "background-color": ['#FFF', '#FFF'],
      "box-shadow": ['2px 2px 10px 0px #00000026', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['8px', '16px'],
      "padding": ['12px', '30px'],
      "margin-top": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": ['500'],
      "color": "primary.400",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_vm._v(" " + _vm._s(_vm.isRefund ? 'Detail Pengembalian' : 'Detail Hadiah') + " ")]), !_vm.isFetching ? _c('c-box', {
    attrs: {
      "as": "table",
      "margin-top": ['8px', '16px']
    }
  }, [(_vm$redeem11 = _vm.redeem) !== null && _vm$redeem11 !== void 0 && _vm$redeem11.id ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Redeem ID ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "4px",
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500']
    }
  }, [_vm._v(" : "), _c('c-text', [_vm._v(" " + _vm._s((_vm$redeem12 = _vm.redeem) === null || _vm$redeem12 === void 0 ? void 0 : _vm$redeem12.idShort) + " ")])], 1)], 1)], 1) : _vm._e(), !_vm.isRefund && (_vm$redeem13 = _vm.redeem) !== null && _vm$redeem13 !== void 0 && _vm$redeem13.status ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Status ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "4px",
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500']
    }
  }, [_vm._v(" : "), _c('c-text', {
    attrs: {
      "color": ((_vm$redeem14 = _vm.redeem) === null || _vm$redeem14 === void 0 ? void 0 : _vm$redeem14.status) == 'success' ? 'primary.400' : ((_vm$redeem15 = _vm.redeem) === null || _vm$redeem15 === void 0 ? void 0 : _vm$redeem15.status) == 'failed' ? 'danger.400' : 'warning.400'
    }
  }, [_vm._v(" " + _vm._s(((_vm$redeem16 = _vm.redeem) === null || _vm$redeem16 === void 0 ? void 0 : _vm$redeem16.status) == 'success' ? 'Berhasil' : ((_vm$redeem17 = _vm.redeem) === null || _vm$redeem17 === void 0 ? void 0 : _vm$redeem17.status) == 'failed' ? 'Gagal' : 'Dalam Proses') + " ")])], 1)], 1)], 1) : _vm._e(), !_vm.isRefund && (_vm$redeem18 = _vm.redeem) !== null && _vm$redeem18 !== void 0 && _vm$redeem18.giftName ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Nama Hadiah ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s((_vm$redeem19 = _vm.redeem) === null || _vm$redeem19 === void 0 ? void 0 : _vm$redeem19.giftName) + " ")])], 1)], 1) : _vm._e(), !_vm.isRefund && (_vm$redeem20 = _vm.redeem) !== null && _vm$redeem20 !== void 0 && _vm$redeem20.merchantName ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Nama Merchant/Bank ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s((_vm$redeem21 = _vm.redeem) === null || _vm$redeem21 === void 0 ? void 0 : _vm$redeem21.merchantName) + " ")])], 1)], 1) : _vm._e(), !_vm.isRefund && (_vm$redeem22 = _vm.redeem) !== null && _vm$redeem22 !== void 0 && _vm$redeem22.accountName ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "min-width": ['120px', '250px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Nama Penerima ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s((_vm$redeem23 = _vm.redeem) === null || _vm$redeem23 === void 0 ? void 0 : _vm$redeem23.accountName) + " ")])], 1)], 1) : _vm._e(), !_vm.isRefund && (_vm$redeem24 = _vm.redeem) !== null && _vm$redeem24 !== void 0 && _vm$redeem24.accountNumber ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "min-width": ['120px', '250px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Nomor e-Wallet / Rekening Bank ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s((_vm$redeem25 = _vm.redeem) === null || _vm$redeem25 === void 0 ? void 0 : _vm$redeem25.accountNumber) + " ")])], 1)], 1) : _vm._e(), !_vm.isRefund && (_vm$redeem26 = _vm.redeem) !== null && _vm$redeem26 !== void 0 && _vm$redeem26.productName ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "min-width": ['120px', '250px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Jenis Program ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s((_vm$redeem27 = _vm.redeem) === null || _vm$redeem27 === void 0 ? void 0 : _vm$redeem27.productName) + " " + _vm._s((_vm$redeem28 = _vm.redeem) === null || _vm$redeem28 === void 0 ? void 0 : _vm$redeem28.programsService) + " (" + _vm._s((_vm$redeem29 = _vm.redeem) === null || _vm$redeem29 === void 0 ? void 0 : _vm$redeem29.duration) + " Hari) ")])], 1)], 1) : _vm._e(), _vm.isRefund && (_vm$refund = _vm.refund) !== null && _vm$refund !== void 0 && _vm$refund.createdAt ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "min-width": ['120px', '250px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Tanggal Pengembalian ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s(_vm.formatDateV2((_vm$refund2 = _vm.refund) === null || _vm$refund2 === void 0 ? void 0 : _vm$refund2.createdAt, 5)) + " WIB ")])], 1)], 1) : _vm._e(), _vm.isRefund && (_vm$refund3 = _vm.refund) !== null && _vm$refund3 !== void 0 && _vm$refund3.amount ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "min-width": ['120px', '250px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Poin Dikembalikan ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s((_vm$refund4 = _vm.refund) === null || _vm$refund4 === void 0 ? void 0 : _vm$refund4.amount) + " poin ")])], 1)], 1) : _vm._e(), !_vm.isRefund && (_vm$redeem30 = _vm.redeem) !== null && _vm$redeem30 !== void 0 && _vm$redeem30.totalPrice ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "min-width": ['120px', '250px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Biaya ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s((_vm$redeem31 = _vm.redeem) === null || _vm$redeem31 === void 0 ? void 0 : _vm$redeem31.totalPrice) + " Poin ")])], 1)], 1) : _vm._e(), (_vm.isRefund ? !!((_vm$refund5 = _vm.refund) !== null && _vm$refund5 !== void 0 && _vm$refund5.note) : !!((_vm$redeem32 = _vm.redeem) !== null && _vm$redeem32 !== void 0 && _vm$redeem32.note)) ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "min-width": ['120px', '250px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Alasan ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s(_vm.isRefund ? (_vm$refund6 = _vm.refund) === null || _vm$refund6 === void 0 ? void 0 : _vm$refund6.note : (_vm$redeem33 = _vm.redeem) === null || _vm$redeem33 === void 0 ? void 0 : _vm$redeem33.note) + " ")])], 1)], 1) : _vm._e(), !_vm.isRefund && (_vm$redeem34 = _vm.redeem) !== null && _vm$redeem34 !== void 0 && _vm$redeem34.createdAt ? _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "min-width": ['120px', '250px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400'],
      "margin-right": "16px",
      "color": "#333"
    }
  }, [_vm._v(" Tanggal Pengajuan ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "color": "#333"
    }
  }, [_vm._v(" : " + _vm._s(_vm.formatDateV2((_vm$redeem35 = _vm.redeem) === null || _vm$redeem35 === void 0 ? void 0 : _vm$redeem35.createdAt, 5)) + " WIB ")])], 1)], 1) : _vm._e()], 1) : _c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "center",
      "align-items": "center",
      "padding-block": "50px"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "5px",
      "speed": "0.65s",
      "empty-color": "primary.200",
      "color": "primary.400",
      "size": "xl"
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "display": "flex",
      "justify-content": "center",
      "margin-top": "20px"
    }
  }, [_c('c-button', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": ['500', '500'],
      "variant-color": "primary",
      "width": "100%",
      "max-width": ['250px', '400px'],
      "rounded": "1000px",
      "size": "lg",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickBack.apply(null, arguments);
      }
    }
  }, [_vm._v(" Cek Riwayat Penukaran ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }