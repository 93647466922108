<template>
  <c-box
    max-width="1270px"
    width="100%"
    :background-color="['#F2F2F2', '#FFF']"
    margin-inline="auto"
    :min-height="['calc(100vh - 62px)', 'unset']"
  >
    <BreadcrumbPath
      px="0px"
      py="20px"
      :paths="[
        { label: 'Dashboard', href: '/' },
        { label: 'Profil', href: '/profile' },
        { label: 'Kode AjakSehat', href: '/profile/referral' },
        { label: 'Riwayat Poin', href: '/profile/referral/redeem?tab=history' },
        { label: isRefund ? 'Pengembalian Poin' : 'Penukaran Hadiah', isCurrent: true },
      ]"
    />
    <c-box
      pos="relative"
      w="100%"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :p="['20px', '30px']"
    >
      <c-box
        display="flex"
        flex-direction="column"
        align-items="center"
      >
        <c-box
          v-if="redeem?.status"
        >
          <c-text
            :font-size="['18px', '20px']"
            :font-weight="['600', '600']"
            text-align="center"
          >
            {{ isRefund
              ? 'Pengembalian Poin'
              : redeem?.status == 'inprogress'
                ? 'Penukaran Sedang Diproses'
                : (redeem?.status == 'success'
                  ? 'Penukaran Hadiah Berhasil'
                  : 'Penukaran Hadiah Gagal') }}
          </c-text>

          <c-image
            :src="isRefund
              ? require('@/assets/images/image-refund.svg')
              : redeem?.status == 'inprogress'
                ? require('@/assets/images/image-in-progress.png')
                : redeem?.status == 'success'
                  ? require('@/assets/images/image-payment-success.svg')
                  : redeem?.status == 'failed'
                    ? require('@/assets/images/illustration-6-raw.png')
                    : require('@/assets/images/image-blank.png')"
            width="100%"
            height="auto"
            :max-width="['200px', '300px']"
            :max-height="['170px', '250px']"
            margin-top="20px"
            margin-inline="auto"
          />

          <c-text
            v-if="redeem?.status == 'inprogress'"
            :margin-top="['8px', '16px']"
            :font-size="['14px', '18px']"
            :font-weight="['400', '500']"
            text-align="center"
            padding-inline="40px"
          >
            Penukaran hadiah akan diproses<br> dalam jangka waktu 1x24 jam
          </c-text>
          <c-text
            v-if="redeem?.status == 'failed' && isRefund"
            :margin-top="['8px', '16px']"
            :font-size="['14px', '18px']"
            :font-weight="['400', '500']"
            text-align="center"
            padding-inline="40px"
          >
            Poin kamu dikembalikan <br>
            karena penukaran hadiah gagal
          </c-text>
          <c-text
            v-else-if="redeem?.status == 'failed' && !isRefund"
            :margin-top="['8px', '16px']"
            :font-size="['14px', '18px']"
            :font-weight="['400', '500']"
            text-align="center"
            padding-inline="40px"
          >
            Penukaran hadiahmu gagal dikarenakan {{ redeem?.note }}
          </c-text>
        </c-box>

        <c-box
          width="100%"
          max-width="900px"
          margin-inline="auto"
          :background-color="['#FFF', '#FFF']"
          :box-shadow="['2px 2px 10px 0px #00000026', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
          :border-radius="['8px', '16px']"
          :padding="['12px', '30px']"
          margin-top="20px"
        >
          <c-text
            :font-size="['16px', '20px']"
            :font-weight="['500']"
            color="primary.400"
            border-bottom="1px solid #C4C4C4"
          >
            {{ isRefund
              ? 'Detail Pengembalian'
              : 'Detail Hadiah'
            }}
          </c-text>
          <c-box
            v-if="!isFetching"
            as="table"
            :margin-top="['8px', '16px']"
          >
            <c-box
              v-if="redeem?.id"
              as="tr"
            >
              <c-box as="td">
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Redeem ID
                </c-text>
              </c-box>
              <c-box as="td">
                <c-flex
                  gap="4px"
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                >
                  :
                  <c-text>
                    {{ redeem?.idShort }}
                  </c-text>
                </c-flex>
              </c-box>
            </c-box>
            
            <c-box
              v-if="!isRefund && redeem?.status"
              as="tr"
            >
              <c-box as="td">
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Status
                </c-text>
              </c-box>
              <c-box as="td">
                <c-flex 
                  gap="4px"
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                >
                  :
                  <c-text
                    :color="redeem?.status == 'success'
                      ? 'primary.400'
                      : (redeem?.status == 'failed'
                        ? 'danger.400'
                        : 'warning.400') "
                  >
                    {{ redeem?.status == 'success'
                      ? 'Berhasil'
                      : (redeem?.status == 'failed'
                        ? 'Gagal'
                        : 'Dalam Proses') }}
                  </c-text>
                </c-flex>
              </c-box>
            </c-box>

            <c-box
              v-if="!isRefund && redeem?.giftName"
              as="tr"
            >
              <c-box as="td">
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Nama Hadiah
                </c-text>
              </c-box>
              <c-box as="td">
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ redeem?.giftName }}
                </c-text>
              </c-box>
            </c-box>
            
            <c-box
              v-if="!isRefund && redeem?.merchantName"
              as="tr"
            >
              <c-box as="td">
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Nama Merchant/Bank
                </c-text>
              </c-box>
              <c-box as="td">
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ redeem?.merchantName }}
                </c-text>
              </c-box>
            </c-box>

            <c-box
              v-if="!isRefund && redeem?.accountName"
              as="tr"
            >
              <c-box
                as="td"
                :min-width="['120px', '250px']"
              >
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Nama Penerima
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="100%"
              >
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ redeem?.accountName }}
                </c-text>
              </c-box>
            </c-box>

            <c-box
              v-if="!isRefund && redeem?.accountNumber"
              as="tr"
            >
              <c-box
                as="td"
                :min-width="['120px', '250px']"
              >
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Nomor e-Wallet / Rekening Bank
                </c-text>
              </c-box>
              <c-box as="td">
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ redeem?.accountNumber }}
                </c-text>
              </c-box>
            </c-box>

            <c-box
              v-if="!isRefund && redeem?.productName"
              as="tr"
            >
              <c-box
                as="td"
                :min-width="['120px', '250px']"
              >
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Jenis Program
                </c-text>
              </c-box>
              <c-box as="td">
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ redeem?.productName }} {{ redeem?.programsService }} ({{ redeem?.duration }} Hari)
                </c-text>
              </c-box>
            </c-box>

            <c-box
              v-if="isRefund && refund?.createdAt"
              as="tr"
            >
              <c-box
                as="td"
                :min-width="['120px', '250px']"
              >
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Tanggal Pengembalian
                </c-text>
              </c-box>
              <c-box as="td">
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ formatDateV2(refund?.createdAt, 5) }} WIB
                </c-text>
              </c-box>
            </c-box>

            <c-box
              v-if="isRefund && refund?.amount"
              as="tr"
            >
              <c-box
                as="td"
                :min-width="['120px', '250px']"
              >
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Poin Dikembalikan
                </c-text>
              </c-box>
              <c-box as="td">
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ refund?.amount }} poin
                </c-text>
              </c-box>
            </c-box>

            <c-box
              v-if="!isRefund && redeem?.totalPrice"
              as="tr"
            >
              <c-box
                as="td"
                :min-width="['120px', '250px']"
              >
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Biaya
                </c-text>
              </c-box>
              <c-box as="td">
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ redeem?.totalPrice }} Poin
                </c-text>
              </c-box>
            </c-box>

            <c-box
              v-if="isRefund ? !!refund?.note : !!redeem?.note"
              as="tr"
            >
              <c-box
                as="td"
                :min-width="['120px', '250px']"
              >
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Alasan
                </c-text>
              </c-box>
              <c-box as="td">
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ isRefund ? refund?.note : redeem?.note }}
                </c-text>
              </c-box>
            </c-box>

            <c-box
              v-if="!isRefund && redeem?.createdAt"
              as="tr"
            >
              <c-box
                as="td"
                :min-width="['120px', '250px']"
              >
                <c-text
                  :font-size="['12px', '16px']"
                  :font-weight="['400']"
                  margin-right="16px"
                  color="#333"
                >
                  Tanggal Pengajuan
                </c-text>
              </c-box>
              <c-box as="td">
                <c-text
                  :font-size="['12px', '18px']"
                  :font-weight="['400', '500']"
                  color="#333"
                >
                  : {{ formatDateV2(redeem?.createdAt, 5) }} WIB
                </c-text>
              </c-box>
            </c-box>
          </c-box>

          <c-flex
            v-else
            width="100%"
            justify-content="center"
            align-items="center"
            padding-block="50px"
          >
            <c-spinner
              thickness="5px"
              speed="0.65s"
              empty-color="primary.200"
              color="primary.400"
              size="xl"
            />
          </c-flex>
        </c-box>

        <c-box
          w="100%"
          display="flex"
          justify-content="center"
          margin-top="20px"
        >
          <c-button
            :font-size="['14px', '16px']"
            :font-weight="['500', '500']"
            variant-color="primary"
            width="100%"
            :max-width="['250px', '400px']"
            rounded="1000px"
            size="lg"
            type="button"
            @click.prevent="onClickBack"
          >
            Cek Riwayat Penukaran
          </c-button>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { reqReferral } from '@/requests/dietela-api/referral'
import { formatDateV2 } from '@/utils/format-date'

export default {
  components: {
    BreadcrumbPath,
  },
  data() {
    return {
      isFetching: false,
      redeem: {},
      refund: {},
    }
  },
  computed: {
    redeemId() {
      return this.$route.params.id
    },
    isRefund() {
      return this.$route.query.refund === 'true'
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    formatDateV2,
    async init() {
      try {
        this.isFetching = true
        const res = await reqReferral.detailRedeem(this.$store.getters.axios, {
          redeemId: this.redeemId,
        })
        this.redeem = res?.data?.data
        if (this.isRefund) {
          const res2 = await reqReferral.detailRedeemRefund(this.$store.getters.axios, {
            redeemId: this.redeemId,
          }).catch(() =>{})
          this.refund = res2?.data?.data
        }
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 3000,
          position: 'bottom-right',
        })
        this.$router.push('/profile/referral/redeem')
      } finally {
        this.isFetching = false
      }
    },
    onClickBack() {
      this.$router.push({
        name: 'client.profile.referral.redeem',
        query: {
          page: 1,
          tab: 'history',
        }, 
      })
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0 8px;
}
</style>