import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=573dc3b9&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=573dc3b9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573dc3b9",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CImage: require('@chakra-ui/vue').CImage, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CSpinner: require('@chakra-ui/vue').CSpinner, CButton: require('@chakra-ui/vue').CButton})
